import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import '../../styles/avatar.css';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faGear,
  faUser,
  faUserCircle,
  faSignOutAlt,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';

function Avatar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(true);
  const [userIdAvatar, setUserIdAvatar] = useState(null);
  const [user, setUser] = useState({
    id: '',
    name: '',
    photo: null,
    avatar_url: '',
  });

  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserId) {
      setUserIdAvatar(parseInt(storedUserId, 10));
    }
  }, []);

  useEffect(() => {
    if (userIdAvatar) {
      const token = sessionStorage.getItem('token');
      axios
        .get(`https://asprovaltam.com/users/${userIdAvatar}`, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [userIdAvatar]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    setIsUserSignedIn(!!token);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleSignOut = () => {
    sessionStorage.clear();
    setIsUserSignedIn(false);
    navigate('/');
  };

  const userId = sessionStorage.getItem('userId');

  const handleMyReservationsClick = () => {
    if (!userId) {
      toast.error('Please log in or register');
    }
  };

  const handleEditClick = () => {
    if (!userId) {
      toast.error('Please log in or register');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleMyReservationsClick();
    }
  };

  return (
    <>
      <button
        className="menu-toggle"
        type="button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        tabIndex="0"
        aria-label="Toggle Menu"
      >
        <div className="avatar">
          {user.avatar_url ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                alt=""
                width="38px"
                height="38px"
                src={`https://asprovaltam.com${user.avatar_url}`}
                onError={(e) => console.error('Error loading image:', e, e.target.src)}
                style={{ cursor: 'pointer', borderRadius: '50%' }}
              />
            </Box>
          ) : (
            <FontAwesomeIcon icon={faUserCircle} size="2x" />
          )}
        </div>
        <FontAwesomeIcon icon={faBars} className="hamburger" />
      </button>
      <ul
        ref={dropdownRef}
        className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}
      >
        {isUserSignedIn ? (
          <>
            <li>
              <Link to="profile/dashboard">
                <FontAwesomeIcon icon={faUser} />
                {' '}
                Profile
              </Link>
            </li>
            <li>
              <Link
                to={`profile/users/${userId}/reservations`}
                onClick={handleMyReservationsClick}
                onKeyDown={handleKeyPress}
              >
                <FontAwesomeIcon icon={faTasks} />
                {' '}
                <Outlet />
                My reservations
              </Link>
            </li>
            <li>
              <Link
                to={`profile/users/${userId}/settings`}
                onClick={handleEditClick}
                onKeyDown={handleKeyPress}
              >
                <FontAwesomeIcon icon={faGear} />
                {' '}
                Settings
              </Link>
            </li>
            <li>
              <Link to="/" onClick={handleSignOut}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                {' '}
                Logout
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/signin">Sign In</Link>
            </li>
            <li>
              <Link to="/signup">Sign Up</Link>
            </li>
          </>
        )}
      </ul>
    </>
  );
}

export default Avatar;
