import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import '../../styles/auth.css';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SigninForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const response = await axios.post(
        'https://asprovaltam.com/users/sign_in',
        {
          user: {
            email,
            password,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const { data, headers } = response;
      const userData = data.status ? data.status.data : data;
      sessionStorage.setItem('userId', userData.id);
      sessionStorage.setItem('userName', userData.name);
      sessionStorage.setItem('userEmail', userData.email);
      sessionStorage.setItem('userPhoneNumber', userData.phone_number);
      sessionStorage.setItem('userCountry', userData.country);
      sessionStorage.setItem('userAvatar', userData.avatar_url);
      const token = headers.authorization;
      sessionStorage.setItem('token', token);
      toast.success('Sign in successful' || '');
      navigate('/');
    } catch (error) {
      toast.dismiss();
      if (
        error.response
        && error.response.data
        && error.response.data.status
        && error.response.data.status.code === 403
      ) {
        toast.error(
          error.response.data.status.message || 'Email not confirmed',
        );
      } else {
        toast.error('Sign in failed - Invalid email/password' || '');
      }
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <section className="section">
      <div className="form-box-signin">
        <div className="form-value">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSignIn();
            }}
          >
            <h2 className="text">Sign In</h2>
            <div className="inputbox">
              <ion-icon className="icon" name="mail-outline" />
              <input
                className="input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email">Email</label>
            </div>
            <div className="inputbox">
              <ion-icon name="lock-closed-outline" />
              <div className="password-wrapper">
                <input
                  className="input"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="password">Password</label>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  className="password-toggle-icon"
                >
                  {showPassword ? (
                    <Visibility style={{ color: 'white' }} />
                  ) : (
                    <VisibilityOff style={{ color: 'white' }} />
                  )}
                </IconButton>
              </div>
            </div>
            <div className="forget">
              <label>
                <input
                  className="input-checkbox"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                Remember Me
              </label>
              <Link to="/forgot">Forgot Password</Link>
            </div>
            <button className="signup-button" type="submit">
              Sign In
            </button>
            <div className="register">
              <p>
                Don&apos;t have an account?
                <Link to="/signup"> Sign Up</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SigninForm;
