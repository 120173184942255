import axios from 'axios';

const getGoogleReviews = async (placeId) => {
  try {
    const response = await axios.get(
      `https://asprovaltam.com/google-maps-api?placeid=${placeId}`,
    );

    if (response.data.status === 'OK') {
      const { reviews } = response.data.result;
      const formattedReviews = reviews.map((review) => ({
        text: review.text,
        rating: review.rating,
        reviewerImage: review.profile_photo_url, // Assuming the API
        reviewerName: review.author_name, // Assuming the API provides the reviewer's name
        time: review.time,
      }));

      return formattedReviews;
    }

    console.error('Error fetching Google Reviews:', response.data.status);
    return [];
  } catch (error) {
    console.error('Error fetching Google Reviews:', error.message);
    return [];
  }
};
export default getGoogleReviews;
