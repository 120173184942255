import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const useQuery = () => new URLSearchParams(useLocation().search);

const ConfirmEmail = () => {
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  const confirmationToken = query.get('confirmation_token');
  const navigate = useNavigate();
  const fetchCalled = useRef(false);

  useEffect(() => {
    const sessionConfirmed = sessionStorage.getItem('confirmed');

    const confirmEmail = async () => {
      if (confirmationToken && !sessionConfirmed && !fetchCalled.current) {
        fetchCalled.current = true;
        const url = `https://asprovaltam.com/users/confirmation?confirmation_token=${confirmationToken}`;

        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          console.log('Received response:', response);
          setLoading(false);

          if (response.status === 422) {
            toast.error('Email was already confirmed, please try signing in.');
            sessionStorage.setItem('confirmed', 'true');
            setTimeout(() => {
              navigate('/signin');
            }, 2000);
            return;
          }

          if (!response.ok) {
            const data = await response.json();
            toast.error(data.errors?.join(', ') || 'An error occurred.');
            throw new Error(data.errors?.join(', ') || 'An error occurred.');
          }

          const data = await response.json();
          if (data.message) {
            toast.success(data.message);
            sessionStorage.setItem('confirmed', 'true');
            setTimeout(() => {
              navigate('/signin');
            }, 2000);
          } else {
            toast.error('Confirmation failed. Please try again.');
          }
        } catch (error) {
          toast.error('An error occurred during confirmation.');
        }
      } else if (sessionConfirmed) {
        toast.success('Email already confirmed.');
        setLoading(false);
      } else {
        console.error('No confirmation token found');
        setLoading(false);
      }
    };

    confirmEmail();
  }, [confirmationToken, navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return null;
};

export default ConfirmEmail;
