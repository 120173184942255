import React from 'react';
import '../styles/paymentsucess.css';

import { useLocation, useParams, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const { reservationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    totalPrice, roomName, userName, startDate, endDate, numNights, email, phoneNumber, country,
  } = location.state;
  const handlePrint = () => {
    window.print();
  };

  const handleClose = () => {
    navigate('/');
  };
  return (
    <div className="center-page">
      <div className="payment-success-wrapper">
        <div className="payment-success-background" />
        <div className="payment-success-container">
          <h1 className="payment-success-title">Payment Successful!</h1>
          <div className="payment-success-info">
            <i className="fas fa-check-circle check-icon" />
            <p className="payment-success-info">
              Reservation ID:
              {' '}
              <span className="reservation-id">{reservationId}</span>
            </p>
            <p className="payment-success-info">
              Room Name:
              {' '}
              <span className="room-name">{roomName}</span>
            </p>
            {/* <p className="payment-success-info">
            User ID:
            {' '}
            <span className="user-id">{userId}</span>
          </p> */}
            <p className="payment-success-info">
              Guest:
              {' '}
              <span className="guest-name">{userName}</span>
            </p>
            <p className="payment-success-info">
              Email:
              {' '}
              <span className="email3">{email}</span>
            </p>
            <p className="payment-success-info">
              Phone:
              {' '}
              <span className="phone-number">{phoneNumber}</span>
            </p>
            <p className="payment-success-info">
              Country:
              {' '}
              <span className="country">{country}</span>
            </p>
            <p className="payment-success-info">
              Check-in:
              {' '}
              <span className="start-date">{startDate}</span>
            </p>
            <p className="payment-success-info">
              Check-out:
              {' '}
              <span className="end-date">{endDate}</span>
            </p>
            <p className="payment-success-info">
              Nights Number:
              {' '}
              <span className="nights-number">{numNights}</span>
            </p>
            <p className="payment-success-info">
              Total Price:
              {' '}
              €
              <span className="total-price">{totalPrice}</span>
            </p>
            <p className="payment-success-info">
              A copy of this form has been sent to your email at:
              {' '}
              <span className="email3">{email}</span>
            </p>
          </div>
          <div className="buttons-print-close">
            <button className="print-button" type="button" onClick={handlePrint}>Print</button>
            <button className="close-button" type="button" onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
