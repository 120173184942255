const PolicyAndTerms = () => (
  <div>
    <p>
      <h3>Table of Contents</h3>
      <b>A1. Our values</b>
      <br />
      <b>A2. Prices</b>
      <br />
      <b>A3. Payment</b>
      <br />
      <b>A4. Policies</b>
      <br />
      <b>A5. Privacy Policy and cookies</b>
      <br />
      <b>A6. Accessibility requests</b>
      <br />
      <b>A7. Insurance</b>
      <br />
      <b>A8. Intellectual property rights</b>
      <br />
      <b>A9. What if something goes wrong?</b>
      <br />
      <b>A10. Communication with the Service Provider</b>
      <br />
      <b>A11. Measures against unacceptable behavior</b>
      <br />
      <b>A12. Limitation of liability</b>
      <br />
      <b>A13. Applicable law and forum</b>
      <br />
      <br />

      <b>A1. Our values</b>
      <br />
      1. You will:
      <ul>
        <li>-Abide by Our values</li>
        <li>-Comply with all applicable laws</li>
        <li>-Cooperate with any anti-fraud/anti-money laundering checks we need to carry out</li>
        <li>-Not use the Platform to cause a nuisance or make fake Reservations</li>
        <li>-Use the Travel Experience and/or Platform for their intended purpose</li>
        <li>-Not cause any nuisance or damage, and not behave inappropriately to the Service Provider’s personnel (or anyone else, for that matter).</li>
      </ul>

      <b>A2. Prices</b>
      <br />
      1. When you make a reservation, you agree to pay the cost of the Travel Experience, including any taxes and charges that may apply.
      <br />
      2. Some of the prices you see may have been rounded to the nearest whole number. The price you pay will be based on the original, “non-rounded” price (although the actual difference will be tiny anyway).
      <br />
      3. Obvious errors and obvious misprints are not binding. For example, if you book a night in a suite that was mistakenly offered for €1, your reservation may be canceled and we’ll refund anything you’ve paid.
      <br />
      4. A crossed-out price indicates the price of a like-for-like Reservation without the price reduction applied.
      <br />
      <br />

      <b>A3. Payment</b>
      <br />
      1. For some products/services, the Service Provider will require an Upfront Payment and/or a payment taken during your Travel Experience.
      <ul>
        <li>If we organize your payment, we (or, in some cases, our affiliate) will be responsible for managing your payment and ensuring the completion of your transaction with our Service Provider. In this case, your payment constitutes final settlement of the “due and payable” price.</li>
        <li>If the Service Provider charges you, this will usually be in person at the start of your Travel Experience, but it could also be (for example) that your credit card is charged when you book, or that you pay when you check out of your Accommodation. This depends on the Upfront Payment policy of the Service Provider as communicated to you in the reservation process.</li>
      </ul>
      2. If the Service Provider requires an Upfront Payment, it may be taken or pre-authorized when you make your Reservation, and it may be non-refundable. Before you book, check the Service Provider’s Upfront Payments policy (available during the reservation process), which we don’t influence and aren’t responsible for. This does not affect your rights if you have any problems with your Travel Experience—please refer to “What if something goes wrong?”.
      <br />
      3. If your payment method is denominated in a currency* that is different from the payment currency, your bank or payment method provider (or their payment services providers) may charge you additional fees. For example, this could happen if your credit card is in Euros but your hotel is charging you in dollars. If this is going to happen, we’ll inform you during the reservation process.
      <br />
      * This just refers to the default currency of your payment method.
      <br />
      4. If you know of or suspect any fraudulent behavior or unauthorized use of your Payment Method, please contact your payment provider as soon as possible.
      <br />
      5. If the currency selected on the Platform isn’t the same as the Service Provider’s currency, we may:
      <ul>
        <li>show prices in your own currency</li>
        <li>offer you the Pay In Your Own Currency option.</li>
      </ul>
      6. Pay In Your Own Currency. We (and/or one of our affiliates) may provide you with the ability to pay for your Reservation in your own currency (your &quot;Home Currency&quot;), based on your location and/or account setting—and in respect of this service only, we do this as principal, rather than as agent for the Service Provider. Where you use this service, you agree to the following terms, which are separate from your contract with the Service Provider related to your Travel Experience. The Service Provider is not involved in the provision of the Pay In Your Own Currency service and is not party to the following terms, which do not give you any extra rights under your contract with the Service Provider. If you choose to pay in the Service Provider’s currency, the following terms don’t apply.
      <ul>
        <li>For the avoidance of doubt, when you use Pay In Your Own Currency, we are simply enabling you to make payment in your own currency while we ensure that the Service Provider is paid in their local currency. As such, you are not making payment in one currency and receiving another currency.</li>
        <li>If you choose to use Pay In Your Own Currency, all fees and charges from us for use of the Pay In Your Own Currency service either (a) are included in the exchange rate or (b) appear as a separate line item (included in the total price displayed where applicable) during the checkout process.</li>
        <li>The exchange rate is determined at the time the total payment (or estimated total payment) is displayed during the check-out process—and where applicable, the total price displayed will be the amount charged by us to you. Just to be clear, certain fees and charges that are part of the total price displayed will, however, be collected directly by the Service Provider. We will tell you during the check-out process when this is the case.</li>
        <li>If you cancel a reservation within any permitted cancellation period which may apply, we will refund you the exact same amount we initially charged you (inclusive of any applicable fees in relation to the Pay in Your Own Currency Service).</li>
      </ul>
      7. We’ll store your Payment Method details for future transactions after collecting your consent.
      <br />
      <br />

      <b>A4. Policies</b>
      <br />
      1. When you make a Reservation, you accept the applicable policies as displayed in the reservation process. You’ll find each Service Provider’s cancellation policy and any other policies (about age requirements, security/damage deposits, additional supplements for group Reservations, extra beds, breakfast, pets, cards accepted, etc.) on our Platform: on the Service Provider information pages, during the reservation process, in the fine print, and in the confirmation email or ticket (if applicable).
      <br />
      2. If you cancel a Reservation or don’t show up, any cancellation/no-show fee or refund will depend on the Service Provider’s cancellation/no-show policy.
      <br />
      3. Some Reservations can’t be canceled for free, while others can only be canceled for free before a deadline.
      <br />
      4. If you book a Travel Experience by paying in advance (including all price components and/or a damage deposit if applicable), the Service Provider may cancel the Reservation without notice if they can’t collect the balance on the date specified. If they do cancel, any non-refundable payment you’ve made will only be refunded at their discretion. It’s your responsibility to make sure the payment goes through on time, that your bank, debit card, or credit card details are correct, and that there’s enough money available in your account.
      <br />
      5. If you think you’re not going to arrive on time, contact your Service Provider and tell them when they can expect you. It’s your responsibility to ensure you’re on time—and if you aren’t, we are not responsible for any associated costs (e.g. the cancellation of your Reservation, or any fees the Service Provider may charge).
      <br />
      6. As the person making the Reservation, you are responsible for the actions and behavior (in relation to the Travel Experience) of everyone in the group. You’re also responsible for obtaining their permission before providing us with their personal data.
      <br />
      <br />

      <b>A5. Privacy Policy and Cookies</b>
      <br />
      As a registered user of our services, you are entrusted with the responsibility to safeguard your credentials, including your username, password, and any other form of authentication used to access our platform. The use of your credentials signifies your agreement to adhere to the following guidelines and policies concerning their usage:
      <br />

      1.
      {' '}
      <b>Confidentiality and Security</b>
      <br />
      You are responsible for maintaining the confidentiality of your login credentials. This includes taking all necessary steps to ensure that your password remains secure and is not disclosed to any unauthorized persons. It is imperative that you select a strong password that is unique and not easily guessable. We recommend using a combination of letters, numbers, and special characters, and avoiding the use of easily obtainable information such as names or birthdates.
      <br />

      2.
      {' '}
      <b>Unauthorized Access</b>
      <br />
      You must notify us immediately if you suspect that your credentials have been compromised or if there has been any unauthorized use of your account. It is your duty to ensure that no other person accesses your account using your credentials. We will not be liable for any loss or damage arising from your failure to comply with this obligation.
      <br />

      3.
      {' '}
      <b>Usage Restrictions</b>
      <br />
      Your credentials are intended solely for your personal use. You may not transfer, sell, or otherwise assign your credentials to any other individual or entity. Using your credentials to access our services for any illegal or unauthorized purpose is strictly prohibited. This includes, but is not limited to, attempting to gain unauthorized access to other accounts, engaging in activities that violate the rights of others, or any form of misuse of our services.
      <br />

      4.
      {' '}
      <b>Account Integrity</b>
      <br />
      You are expected to provide accurate and truthful information during the registration process and to keep this information up to date. Any changes to your account information must be promptly updated to ensure the integrity and security of your account.
      <br />

      5.
      {' '}
      <b>Monitoring and Enforcement</b>
      <br />
      We reserve the right to monitor the use of your credentials and to take appropriate action if we detect any unusual or suspicious activity. This may include temporarily suspending or permanently terminating your account if we believe that your credentials have been compromised or if you have violated any terms of this agreement. Additionally, we may request additional information or identification from you to verify the legitimacy of your account usage.
      <br />
      <br />

      <b>A6. Accessibility requests</b>
      <br />
      1. If you have any accessibility requests, contact our Customer Service team. We will do everything possible to help you find an appropriate Travel Experience.
      <br />
      2. Depending on your accessibility needs (e.g. wheelchair access, assistance animal), the Service Provider will either confirm or reject your request. They can reject your request if it doesn’t comply with their accessibility policies.
      <br />
      <br />

      <b>A7. Insurance</b>
      <br />
      1. We advise you to take out appropriate travel insurance to cover your entire Travel Experience.
      <br />
      2. If you buy insurance through our Platform, the contract will be between you and the insurance provider, whose terms will apply to the insurance coverage. We are not a party to your insurance contract.
      <br />
      <br />

      <b>A8. Intellectual property rights</b>
      <br />
      1. Unless otherwise stated, all the software required for our services or available on our Platform, and the intellectual property rights (including the copyrights) of the content and information and material on our Platform, are owned by us or our Service Providers.
      <br />
      2. Our logos are registered trademarks. You can only use them if you are in receipt of our written consent. All other brands of products or services on our Platform belong to their respective owners.
      <br />
      <br />

      <b>A9. What if something goes wrong?</b>
      <br />
      1. If you have any problems, you can contact our Customer Service team through our Platform or by other means. You can find our contact details on your booking confirmation or on our Platform.
      <br />
      2. We will try to help you resolve any issues related to our services. If you have any complaints about the Service Provider, you must resolve them directly with the Service Provider.
      <br />
      3. If we receive a complaint about your behavior, we may need to conduct an investigation, and we will notify you of any actions we may take, including suspension of your access to our Platform or termination of your account.
      <br />
      <br />

      <b>A10. Communication with the Service Provider</b>
      <br />
      1. We will provide you with the contact details of the Service Provider after your Reservation. You may also contact the Service Provider through our Platform if we offer this feature.
      <br />
      2. If you have any complaints about the Service Provider, you must resolve them directly with the Service Provider.
      <br />
      3. We may act as an intermediary between you and the Service Provider to help you resolve any issues.
      <br />
      <br />

      <b>A11. Measures against unacceptable behavior</b>
      <br />
      1. If we receive a complaint about your behavior, we may need to conduct an investigation, and we will notify you of any actions we may take, including suspension of your access to our Platform or termination of your account.
      <br />
      2. We may refuse you access to our Platform and/or cancel your Reservation if we reasonably believe you have breached any of our policies or caused harm to the Service Provider or any other third party.
      <br />
      3. We reserve the right to take legal action against you if necessary.
      <br />
      <br />

      <b>A12. Limitation of liability</b>
      <br />
      1. We will not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our Platform or the Travel Experience, whether based on contract, tort, strict liability, or otherwise, except to the extent that such liability cannot be excluded or limited by law.
      <br />
      2. Our total liability to you for any claim arising out of or in connection with these Terms and Conditions or your use of our Platform or the Travel Experience will not exceed the amount you paid for the Travel Experience, except to the extent that such liability cannot be excluded or limited by law.
      <br />
      3. Nothing in these Terms and Conditions will exclude or limit our liability for death or personal injury caused by our negligence, fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by law.
      <br />
      <br />

      <b>A13. Applicable law and forum</b>
      <br />
      1. These Terms and Conditions and any disputes arising out of or in connection with them will be governed by the laws of the country where our company is registered.
      <br />
      2. Any disputes arising out of or in connection with these Terms and Conditions will be subject to the exclusive jurisdiction of the courts of the country where our company is registered.
      <br />
    </p>
  </div>
);

export default PolicyAndTerms;
