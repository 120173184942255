import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../../styles/auth.css';
import { toast } from 'react-hot-toast';
import { Button } from 'antd';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';

const NotificationAccConf = () => {
  const location = useLocation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    console.log('Location state:', location.state);
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    } else {
      console.error('Email not found in location state');
    }
  }, [location.state]);

  const handleResendEmail = async () => {
    try {
      const response = await fetch(
        'https://asprovaltam.com/users/resend_confirmation',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        },
      );

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
      } else {
        const data = await response.json();
        toast.error(data.error || 'Failed to resend confirmation email.');
      }
    } catch (error) {
      console.error('Error resending confirmation:', error);
      toast.error('An error occurred while resending confirmation.');
    }
  };

  return (
    <section className="section">
      <div className="form-container">
        <div className="form-box-confirm">
          <form>
            <h1 className="text">Just one more step...</h1>
            <p className="text-policy">
              Please verify your email address so you can sign in if you ever
              forget your password.
            </p>
            <p className="text-policy">We have sent a confirmation email to:</p>
            <p className="email-text">{email}</p>

            <p className="text-policy">
              If you still don&apos;t see it, you can resend the confirmation
              email
            </p>
            <div className="email-container">
              <Button
                variant="row"
                type="button"
                className="resent-email"
                onClick={handleResendEmail}
              >
                <EmailIcon className="email-icon" fontSize="50px" />
              </Button>
            </div>
            <div className="home-container">
              <Link to="/" className="home-link">
                <span className="back-home">Back to</span>
                <HomeIcon className="home-icon" fontSize="50px" />
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NotificationAccConf;
