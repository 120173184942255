import React from 'react';
import Sidebar from '../Dashboard/Sidebar/Sidebar';
import Content from '../Dashboard/Content/Content';
import './content.css';

function Profile() {
  return (
    <div className="content">
      <div className="AppGlass">
        <Sidebar />
        <Content />
      </div>
    </div>
  );
}

export default Profile;
