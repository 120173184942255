import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import RoomImages from './RoomImages';
import RoomAmenities from './RoomAmenities';

const Popup = ({
  roomName,
  openPopupId,
  onClose,
  roomId,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const isOpen = openPopupId !== null && openPopupId !== undefined;

  let roomInfo;
  if (roomName in RoomImages) {
    roomInfo = RoomImages[roomName];
  } else {
    roomInfo = RoomImages.Other;
  }

  const { images } = roomInfo;

  const closePopup = () => {
    onClose();
  };

  const handleReserveClick = () => {
    const userId = sessionStorage.getItem('userId');
    if (userId) {
      if (roomId) {
        sessionStorage.setItem('roomId', roomId);
        sessionStorage.setItem('roomName', roomName);
        navigate(`/users/${userId}/rooms/${roomId}/reservations`);
      } else {
        toast.error('Invalid roomId:', roomId);
      }
    } else {
      toast.dismiss();
      toast.error('Please Login or Register');
      navigate('/signin');
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentImage);
    }
  }, [currentImage]);

  if (!isOpen) {
    return null;
  }

  const PrevArrow = ({ onClick }) => (
    <button type="button" className="slick-arrow slick-prev" onClick={onClick}>
      Previous
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button type="button" className="slick-arrow slick-next" onClick={onClick}>
      Next
    </button>
  );

  PrevArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  NextArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    initialSlide: currentImage,
  };

  const handleImageHover = (index) => {
    setCurrentImage(index);
  };
  return ReactDOM.createPortal(
    <div className="popup">
      <div className="popup-inner">
        <div className="room_close">
          <h2>{roomName}</h2>
          <div className="btn">
            <button type="button" className="close_btn" onClick={closePopup}>
              X
            </button>
          </div>
        </div>
        <div className="popup-content">
          <div className="slider_other_image">
            <div className="slider-my-container">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Slider ref={sliderRef} {...settings}>
                {images.map((image, index) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <div className="slider-container" key={image.src + index}>
                    <img
                      src={image.src}
                      alt={roomName}
                      className="slider-image"
                      style={{
                        maxWidth: 430,
                        maxHeight: 350,
                        width: 'auto',
                        height: 'auto',
                        margin: 'auto',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="other-images">
              {images.slice(1).map((image, index) => (
                <img
                  key={image.src}
                  src={image.src}
                  alt={roomName}
                  className="other-image"
                  onMouseEnter={() => handleImageHover(index + 1)}
                />
              ))}
            </div>

          </div>
          <div className="room-description">
            <RoomAmenities roomName={roomName} />
          </div>
        </div>

      </div>
      <div className="popup-footer">
        <button type="button" className="reserve-btn" onClick={handleReserveClick}>
          BOOK NOW
        </button>
      </div>
    </div>,
    document.body,
  );
};

Popup.defaultProps = {
  openPopupId: null,
  roomId: null,
};

Popup.propTypes = {
  roomName: PropTypes.string.isRequired,
  openPopupId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  roomId: PropTypes.number,
};

export default Popup;
