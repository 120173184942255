import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './myreservations.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import StripeCheckout from 'react-stripe-checkout';
import toast from 'react-hot-toast';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';
import logo from '../../../images/logo.png';

const MyReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unpaidReservationsCount, setUnpaidReservationsCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery({ query: '(min-width: 1440px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 1024px) and (max-width: 1439px)',
  });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1023px)' });

  const fetchReservations = useCallback(async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem('token');

      const response = await axios.get(
        `https://asprovaltam.com/users/${userId}/reservations`,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );

      const reservationsData = response.data;
      setReservations(reservationsData);

      // Count the number of unpaid reservations
      const unpaidCount = reservationsData.filter(
        (reservation) => reservation.payment_status === 'pending',
      ).length;
      setUnpaidReservationsCount(unpaidCount);

      console.log('User Reservations:', reservationsData);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      toast.error('Failed to fetch reservations. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchReservations();
  }, [fetchReservations]);

  const sendTokenToBackend = async (
    stripeToken,
    roomId,
    reservationId,
    reservation,
  ) => {
    setModalVisible(true);
    try {
      const storedToken = sessionStorage.getItem('token');
      const token = storedToken && storedToken.startsWith('Bearer ')
        ? storedToken.replace('Bearer ', '')
        : storedToken;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Reservation-Id': reservationId,
        'X-Stripe-Token': stripeToken.id,
      };

      const response = await fetch(
        `https://asprovaltam.com/users/${userId}/rooms/${roomId}/reservations/${reservationId}/create_payment`,
        {
          method: 'POST',
          headers,
        },
      );

      if (response.ok) {
        navigate(
          `/users/${userId}/rooms/${roomId}/reservations/${reservationId}/payment`,
          {
            state: {
              totalPrice: reservation.total_price,
              roomName: reservation.room.name,
              userName: reservation.user.name,
              email: reservation.user.email,
              phoneNumber: reservation.user.phone_number,
              country: reservation.user.country,
              startDate: reservation.start_date,
              endDate: reservation.end_date,
              numNights: reservation.num_nights,
            },
          },
        );
      } else {
        const error = await response.json();
        console.error('Error processing payment:', error);
        toast.error(`Error processing payment: ${error.error}`);
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error. Please try again.');
    } finally {
      setModalVisible(false);
    }
  };

  const onToken = (token, roomId, reservationId, reservation) => {
    console.log('Received Token:', token);
    console.log('Room ID:', roomId);
    console.log('Reservation ID:', reservationId);

    sendTokenToBackend(token, roomId, reservationId, reservation);
  };

  const deleteReservation = async (reservationId) => {
    try {
      const token = sessionStorage.getItem('token');

      const response = await axios.delete(
        `https://asprovaltam.com/users/${userId}/reservations/${reservationId}`,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        setReservations(
          reservations.filter((reservation) => reservation.id !== reservationId),
        );

        // Update the unpaid reservations count
        setUnpaidReservationsCount(unpaidReservationsCount - 1);

        toast.success('Reservation deleted successfully');
      } else {
        console.error('Failed to delete reservation');
        toast.error('Failed to delete reservation');
      }
    } catch (error) {
      console.error('Error deleting reservation:', error);
      toast.error('Error deleting reservation');
    }
  };

  const renderPendingReservationsCarousel = () => {
    const filteredReservations = reservations.filter(
      (reservation) => reservation.payment_status === 'pending',
    );

    const getGroupSize = () => {
      if (isWideScreen) return 3;
      if (isMediumScreen) return 2;
      return 1;
    };

    const groups = [];
    const groupSize = getGroupSize();
    for (let i = 0; i < filteredReservations.length; i += groupSize) {
      groups.push(filteredReservations.slice(i, i + groupSize));
    }

    return (
      <Carousel showArrows showThumbs={false} infiniteLoop>
        {groups.map((group) => (
          <div
            key={group.map((item) => item.id).join('-')}
            className="carousel-group"
          >
            {group.map((reservation) => (
              <div key={reservation.id} className="reservation-card">
                <img
                  src="https://media.istockphoto.com/id/1398814566/photo/interior-of-small-apartment-living-room-for-home-office.jpg?s=612x612&w=0&k=20&c=8clwg8hTpvoEwL7253aKdYAUuAp1-usFOacNR5qX-Rg="
                  alt=""
                  className="images"
                />
                <h2>
                  Room:
                  {reservation.room.name}
                </h2>
                <p>
                  Guest:
                  {reservation.user.name}
                </p>
                <p>
                  Check-in Date:
                  {reservation.start_date}
                </p>
                <p>
                  Check-out Date:
                  {reservation.end_date}
                </p>
                <p>
                  Number of Nights:
                  {reservation.num_nights}
                </p>
                <p>
                  Total Price: €
                  {reservation.total_price}
                </p>
                <p>
                  Payment Status:
                  {reservation.payment_status}
                </p>
                <div className="stripe_button_delete">
                  {reservation.payment_status === 'pending' && (
                    <StripeCheckout
                      amount={reservation.total_price * 100}
                      token={(stripeToken) => onToken(
                        stripeToken,
                        reservation.room_id,
                        reservation.id,
                        reservation,
                      )}
                      currency="EUR"
                      stripeKey="pk_test_D8Bf6meISJ0rowxLnUUPsWyF"
                    >
                      <div className="button-container1">
                        <button type="button" className="pay-button">
                          Pay Now
                        </button>
                      </div>
                    </StripeCheckout>
                  )}
                  <button
                    type="button"
                    onClick={() => deleteReservation(reservation.id)}
                    className="delete"
                    aria-label="Delete"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    <span className="delete-label">Delete</span>
                  </button>
                </div>
                <div className="info_text">
                  <FontAwesomeIcon icon={faCircleInfo} />
                  <p>
                    Please note that reservations will automatically be deleted
                    if payment is not completed within five minutes.
                  </p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    );
  };

  const renderPendingReservationCard = (reservation) => (
    <div key={reservation.id} className="reservation-card">
      <img
        src="https://media.istockphoto.com/id/1398814566/photo/interior-of-small-apartment-living-room-for-home-office.jpg?s=612x612&w=0&k=20&c=8clwg8hTpvoEwL7253aKdYAUuAp1-usFOacNR5qX-Rg="
        alt=""
        className="images"
      />
      <h2>
        Room:
        {reservation.room.name}
      </h2>
      <p>
        Guest:
        {reservation.user.name}
      </p>
      <p>
        Check-in Date:
        {reservation.start_date}
      </p>
      <p>
        Check-out Date:
        {reservation.end_date}
      </p>
      <p>
        Number of Nights:
        {reservation.num_nights}
      </p>
      <p>
        Total Price: €
        {reservation.total_price}
      </p>
      <p>
        Payment Status:
        {reservation.payment_status}
      </p>
      <div className="stripe_button_delete">
        {reservation.payment_status === 'pending' && (
          <StripeCheckout
            amount={reservation.total_price * 100}
            token={(stripeToken) => onToken(
              stripeToken,
              reservation.room_id,
              reservation.id,
              reservation,
            )}
            currency="EUR"
            stripeKey="pk_test_D8Bf6meISJ0rowxLnUUPsWyF"
          >
            <div className="button-container1">
              <button type="button" className="pay-button">
                Pay Now
              </button>
            </div>
          </StripeCheckout>
        )}
        <button
          type="button"
          onClick={() => deleteReservation(reservation.id)}
          className="delete"
          aria-label="Delete"
        >
          <FontAwesomeIcon icon={faTrash} />
          <span className="delete-label">Delete</span>
        </button>
      </div>
      <div className="info_text">
        <FontAwesomeIcon icon={faCircleInfo} />
        <p>
          Please note that reservations will automatically be deleted if payment
          is not completed within five minutes.
        </p>
      </div>
    </div>
  );

  const renderPaidReservationsTable = () => (
    <table>
      <thead>
        <tr>
          <th>Room</th>
          <th>Guest</th>
          <th>Check-in Date</th>
          <th>Check-out Date</th>
          <th>Number of Nights</th>
          <th>Total Price</th>
          <th>Payment Status</th>
        </tr>
      </thead>
      <tbody>
        {reservations
          .filter((reservation) => reservation.payment_status === 'paid')
          .map((reservation) => (
            <tr key={reservation.id}>
              <td>{reservation.room.name}</td>
              <td>{reservation.user.name}</td>
              <td>{reservation.start_date}</td>
              <td>{reservation.end_date}</td>
              <td>{reservation.num_nights}</td>
              <td>
                €
                {reservation.total_price}
              </td>
              <td>{reservation.payment_status}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const renderContent = () => {
    if (loading) {
      return <div className="loading">Loading reservations...</div>;
    }

    if (reservations.length === 0) {
      return (
        <div className="no-reservations">
          <p>No reservations found.</p>
        </div>
      );
    }

    if (isSmallScreen && unpaidReservationsCount === 1) {
      return renderPendingReservationCard(
        reservations.find(
          (reservation) => reservation.payment_status === 'pending',
        ),
      );
    }

    if (isMediumScreen && unpaidReservationsCount <= 2) {
      return reservations
        .filter((reservation) => reservation.payment_status === 'pending')
        .map(renderPendingReservationCard);
    }

    if (isWideScreen && unpaidReservationsCount <= 3) {
      return reservations
        .filter((reservation) => reservation.payment_status === 'pending')
        .map(renderPendingReservationCard);
    }

    return renderPendingReservationsCarousel();
  };

  return (
    <div>
      <div className="content--header">
        <h2 className="header--title">My Reservations</h2>
      </div>
      <div className="myreservations-container">
        <div className="reservation-container">{renderContent()}</div>
        {reservations.length > 0 && renderPaidReservationsTable()}
      </div>
      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <img className="logoB" src={logo} alt="logo.png" />
            <p>Processing payment, please wait...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyReservations;
