// RoomAmenities.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faWifi,
  faTv,
  faUtensils,
  faParking,
  faSmokingBan,
  faSolarPanel,
  faShower,
  faTshirt,
  faSnowflake,
  faCheck,
  faUser,
  faSquareCaretUp,
  faSquareCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/roomamenities.css';

const roomAmenities = {
  'Studio Jana': {
    description: 'Boasting a private entrance, this air-conditioned apartment includes 1 living room, 1 separate bedroom and 1 bathroom with a bath or a shower. In the kitchen, guests will find a stovetop, a refrigerator and kitchenware. Featuring a balcony with mountain views, this apartment also offers soundproof walls and a flat-screen TV with satellite channels. The unit has 2 beds.',
    apartmentSize: '20 m²',
    balconySize: '6 m²',
    capacity: [
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
    ],
    amenitiesZero: [
      { icon: faWifi, text: ' Free WiFi' },
      { icon: faTv, text: ' Satellite TV' },
      { icon: faUtensils, text: ' Kitchenette available' },
    ],
    amenitiesFirst: [
      { icon: faParking, text: ' Free parking available' },
      { icon: faSnowflake, text: ' Air conditioning' },
      { icon: faSmokingBan, text: ' Smoking is forbidden' },

    ],
    amenitiesSecond: [
      { icon: faSolarPanel, text: ' Solar water heater' },
      { icon: faShower, text: ' Bathroom' },
      { icon: faTshirt, text: ' Iron' },

    ],

    bedIcons: [
      { icon: faBed, text: ' 1 twin bed' },
      { icon: faBed, text: ' 1 full bed' },
    ],
    privateKitchenOne: [
      { icon: faCheck, text: 'Refrigerator' },
      { icon: faCheck, text: 'Outdoor furniture' },
    ],
    privateKitchenTwo: [
      { icon: faCheck, text: 'Stovetop' },
      { icon: faCheck, text: 'Kitchenware' },
    ],
    privateKitchenThree: [
      { icon: faCheck, text: 'Outdoor dining area' },
      { icon: faCheck, text: 'Dining table' },
    ],
    privateBathroomOne: [
      { icon: faCheck, text: 'Toilet' },
    ],
    privateBathroomTwo: [
      { icon: faCheck, text: 'Bathtub or shower' },
    ],
    privateBathroomThree: [
      { icon: faCheck, text: 'Toilet paper' },
    ],
    viewOne: [
      { icon: faCheck, text: 'Balcony' },
    ],
    viewTwo: [
      { icon: faCheck, text: 'Mountain view' },
    ],
    viewThree: [
      { icon: faCheck, text: 'City view' },
    ],
    facilitiesOne: [
      { icon: faCheck, text: 'Dining table' },
      { icon: faCheck, text: 'Upper floors accessible by stairs only' },
      { icon: faCheck, text: 'Flat-screen TV' },
      { icon: faCheck, text: 'Outdoor furniture' },
      { icon: faCheck, text: 'Iron' },
      { icon: faCheck, text: 'Towels' },
      { icon: faCheck, text: 'Private apartment in building' },
      { icon: faCheck, text: 'Ironing facilities' },
    ],
    facilitiesTwo: [
      { icon: faCheck, text: 'Sitting area' },
      { icon: faCheck, text: 'TV' },
      { icon: faCheck, text: 'Refrigerator' },
      { icon: faCheck, text: 'Dining table' },
      { icon: faCheck, text: 'Linens' },
      { icon: faCheck, text: 'Stovetop' },
      { icon: faCheck, text: 'Tile/Marble floor' },
      { icon: faCheck, text: 'Private entrance' },
    ],
    facilitiesThree: [
      { icon: faCheck, text: 'Kitchenware' },
      { icon: faCheck, text: 'Kitchen' },
      { icon: faCheck, text: 'Heating' },
      { icon: faCheck, text: 'Wardrobe or closet' },
      { icon: faCheck, text: 'Outdoor dining area' },
      { icon: faCheck, text: 'Soundproof' },
      { icon: faCheck, text: 'Satellite channels' },
      { icon: faCheck, text: 'Air conditioning' },
      { icon: faCheck, text: 'Hand sanitizer' },
    ],

  },
  'Apartment Damjan': {
    description: 'Featuring a private entrance, this air-conditioned apartment consists of 2 bedrooms and 1 bathroom with a bath or a shower. The kitchen is equipped with a stovetop, a refrigerator and kitchenware. Boasting a balcony with mountain views, this apartment also provides soundproof walls and a flat-screen TV with satellite channels. The unit offers 4 beds.',

    apartmentSize: ' 34 m²',
    balconySize: '10 m²',
    capacity: [
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
    ],
    amenitiesZero: [
      { icon: faWifi, text: ' Free WiFi' },
      { icon: faTv, text: ' Satellite TV' },
      { icon: faUtensils, text: ' Kitchenette available' },
    ],
    amenitiesFirst: [
      { icon: faParking, text: ' Free parking available' },
      { icon: faSnowflake, text: ' Air conditioning' },
      { icon: faSmokingBan, text: ' Smoking is forbidden' },
    ],
    amenitiesSecond: [
      { icon: faSolarPanel, text: ' Solar water heater' },
      { icon: faShower, text: ' Bathroom' },
      { icon: faTshirt, text: ' Iron' },
    ],
    bedIcons: [
      { icon: faBed, text: ' 4 x 1 twin beds' },
      // { icon: faBed, text: ' 2 twin beds' },
    ],
    privateKitchenOne: [
      { icon: faCheck, text: ' Refrigerator' },
      { icon: faCheck, text: ' Outdoor furniture' },
    ],
    privateKitchenTwo: [
      { icon: faCheck, text: ' Stovetop' },
      { icon: faCheck, text: ' Kitchenware' },
    ],
    privateKitchenThree: [
      { icon: faCheck, text: ' Outdoor dining area' },
      { icon: faCheck, text: ' Dining table' },
    ],
    privateBathroomOne: [
      { icon: faCheck, text: 'Toilet' },
    ],
    privateBathroomTwo: [
      { icon: faCheck, text: 'Bathtub or shower' },
    ],
    privateBathroomThree: [
      { icon: faCheck, text: 'Toilet paper' },
    ],
    viewOne: [
      { icon: faCheck, text: 'Balcony' },
    ],
    viewTwo: [
      { icon: faCheck, text: 'Mountain view' },
    ],
    viewThree: [
      { icon: faCheck, text: 'City view' },
    ],
    facilitiesOne: [
      { icon: faCheck, text: 'Dining table' },
      { icon: faCheck, text: 'Upper floors accessible by stairs only' },
      { icon: faCheck, text: 'Flat-screen TV' },
      { icon: faCheck, text: 'Outdoor furniture' },
      { icon: faCheck, text: 'Iron' },
      { icon: faCheck, text: 'Towels' },
      { icon: faCheck, text: 'Private apartment in building' },
      { icon: faCheck, text: 'Ironing facilities' },
    ],
    facilitiesTwo: [
      { icon: faCheck, text: 'Sitting area' },
      { icon: faCheck, text: 'TV' },
      { icon: faCheck, text: 'Refrigerator' },
      { icon: faCheck, text: 'Dining table' },
      { icon: faCheck, text: 'Linens' },
      { icon: faCheck, text: 'Stovetop' },
      { icon: faCheck, text: 'Tile/Marble floor' },
      { icon: faCheck, text: 'Private entrance' },
    ],
    facilitiesThree: [
      { icon: faCheck, text: 'Kitchenware' },
      { icon: faCheck, text: 'Kitchen' },
      { icon: faCheck, text: 'Heating' },
      { icon: faCheck, text: 'Wardrobe or closet' },
      { icon: faCheck, text: 'Outdoor dining area' },
      { icon: faCheck, text: 'Soundproof' },
      { icon: faCheck, text: 'Satellite channels' },
      { icon: faCheck, text: 'Air conditioning' },
      { icon: faCheck, text: 'Hand sanitizer' },
    ],
  },
  Other: {
    description: 'Explore our other available spaces.',

    apartmentSize: '/ m²',
    balconySize: '/ m²',

    amenitiesZero: [
      { icon: faWifi, text: ' Free WiFi' },
      { icon: faTv, text: ' Satellite TV' },
      { icon: faUtensils, text: ' Kitchenette available' },
    ],
    capacity: [
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
      { icon: faUser, text: '' },
    ],
    amenitiesFirst: [
      { icon: faParking, text: ' Free parking available' },
      { icon: faSnowflake, text: ' Air conditioning' },
      { icon: faSmokingBan, text: ' Smoking is forbidden' },
    ],
    amenitiesSecond: [
      { icon: faSolarPanel, text: ' Solar water heater' },
      { icon: faShower, text: ' Bathroom' },
      { icon: faTshirt, text: ' Iron' },
    ],
    privateKitchenOne: [
      { icon: faCheck, text: 'Refrigerator' },
      { icon: faCheck, text: 'Outdoor furniture' },
    ],
    privateKitchenTwo: [
      { icon: faCheck, text: ' Stovetop' },
      { icon: faCheck, text: ' Kitchenware' },
    ],
    privateKitchenThree: [
      { icon: faCheck, text: ' Outdoor dining area' },
      { icon: faCheck, text: ' Dining table' },
    ],
    privateBathroomOne: [
      { icon: faCheck, text: 'Toilet' },
    ],
    privateBathroomTwo: [
      { icon: faCheck, text: 'Bathtub or shower' },
    ],
    privateBathroomThree: [
      { icon: faCheck, text: 'Toilet paper' },
    ],
    viewOne: [
      { icon: faCheck, text: 'Balcony' },
    ],
    viewTwo: [
      { icon: faCheck, text: 'Mountain view' },
    ],
    viewThree: [
      { icon: faCheck, text: 'City view' },
    ],
    facilitiesOne: [
      { icon: faCheck, text: 'Dining table' },
      { icon: faCheck, text: 'Upper floors accessible by stairs only' },
      { icon: faCheck, text: 'Flat-screen TV' },
      { icon: faCheck, text: 'Outdoor furniture' },
      { icon: faCheck, text: 'Iron' },
      { icon: faCheck, text: 'Towels' },
      { icon: faCheck, text: 'Private apartment in building' },
      { icon: faCheck, text: 'Ironing facilities' },
    ],
    facilitiesTwo: [
      { icon: faCheck, text: 'Sitting area' },
      { icon: faCheck, text: 'TV' },
      { icon: faCheck, text: 'Refrigerator' },
      { icon: faCheck, text: 'Dining table' },
      { icon: faCheck, text: 'Linens' },
      { icon: faCheck, text: 'Stovetop' },
      { icon: faCheck, text: 'Tile/Marble floor' },
      { icon: faCheck, text: 'Private entrance' },
    ],
    facilitiesThree: [
      { icon: faCheck, text: 'Kitchenware' },
      { icon: faCheck, text: 'Kitchen' },
      { icon: faCheck, text: 'Heating' },
      { icon: faCheck, text: 'Wardrobe or closet' },
      { icon: faCheck, text: 'Outdoor dining area' },
      { icon: faCheck, text: 'Soundproof' },
      { icon: faCheck, text: 'Satellite channels' },
      { icon: faCheck, text: 'Air conditioning' },
      { icon: faCheck, text: 'Hand sanitizer' },
    ],
  },
};

const RoomAmenities = ({ roomName }) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedone, setExpandedOne] = useState(false);
  const [expandedtwo, setExpandedTwo] = useState(false);
  const [expandedthree, setExpandedThree] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleExpandedOne = () => {
    setExpandedOne(!expandedone);
  };
  useEffect(() => {
    const handleResizeOne = () => {
      if (window.innerWidth < 768) {
        setExpandedOne(false);
      } else {
        setExpandedOne(true);
      }
    };
    window.addEventListener('resize', handleResizeOne);
    return () => {
      window.removeEventListener('resize', handleResizeOne);
    };
  }, []);

  const toggleExpandedTwo = () => {
    setExpandedTwo(!expandedtwo);
  };
  useEffect(() => {
    const handleResizeTwo = () => {
      if (window.innerWidth < 768) {
        setExpandedTwo(false);
      } else {
        setExpandedTwo(true);
      }
    };
    window.addEventListener('resize', handleResizeTwo);
    return () => {
      window.removeEventListener('resize', handleResizeTwo);
    };
  }, []);

  const toggleExpandedThree = () => {
    setExpandedThree(!expandedthree);
  };
  useEffect(() => {
    const handleResizeThree = () => {
      if (window.innerWidth < 768) {
        setExpandedThree(false);
      } else {
        setExpandedThree(true);
      }
    };
    window.addEventListener('resize', handleResizeThree);
    return () => {
      window.removeEventListener('resize', handleResizeThree);
    };
  }, []);

  const {
    description, apartmentSize, balconySize, amenitiesZero, amenitiesFirst, amenitiesSecond, bedIcons, privateKitchenOne, privateKitchenTwo, privateKitchenThree, privateBathroomOne, privateBathroomTwo, privateBathroomThree, viewOne, viewTwo, viewThree, facilitiesOne, facilitiesTwo, facilitiesThree, capacity,
  } = roomAmenities[roomName] || {
    description: '', apartmentSize: '', amenitiesZero: [], amenitiesFirst: [], amenitiesSecond: [], bedIcons: [], privateKitchenOne: [], privateKitchenTwo: [], privateKitchenThree: [], privateBathroomOne: [], privateBathroomTwo: [], privateBathroomThree: [], viewOne: [], viewTwo: [], viewThree: [], facilitiesOne: [], facilitiesTwo: [], facilitiesThree: [], balconySize: '', capacity: [],
  };

  return (
    <div className="roomamenities_container">

      <div className="icons_room">
        <div className="icons_room_0">
          { amenitiesZero.map(({ icon, text }, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>{text}</span>
            </div>
          ))}
        </div>
        <div className="icons_room_1">
          { amenitiesFirst.map(({ icon, text }, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>{text}</span>
            </div>
          ))}
        </div>
        <div className="icons_room_2">
          {amenitiesSecond.map(({ icon, text }, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>{text}</span>
            </div>
          ))}
        </div>
      </div>
      <p>
        <strong>Apartment Size:</strong>
        {' '}
        {apartmentSize}
      </p>
      <p>
        <strong>
          Balcony Size:
        </strong>
        {' '}
        {balconySize}
      </p>
      <p className="capacity_p">
        <strong>
          Capacity:
          {' '}
        </strong>
        {' '}
        <div className="capacity_icons">
          {capacity.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>{text}</span>
            </div>
          ))}
        </div>
      </p>
      <div className="bed_icons">
        {bedIcons.map(({ icon, text }, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <div key={index}>
            <FontAwesomeIcon icon={icon} />
            <span>{text}</span>
          </div>
        ))}
      </div>
      <div className="description">
        <p>{description}</p>
      </div>
      <div
        className="expand-toggle"
        onClick={toggleExpanded}
        onKeyDown={toggleExpanded}
        role="button"
        tabIndex={0}
      >
        <strong>Private kitchen</strong>
        {' '}
        <FontAwesomeIcon icon={expanded ? faSquareCaretUp : faSquareCaretDown} />
      </div>

      {expanded && (
      <div className="private_kitchen">

        <div className="private_kitchen_1">
          {privateKitchenOne.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="private_kitchen_2">
          {privateKitchenTwo.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="private_kitchen_3">
          {privateKitchenThree.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
      </div>
      )}

      <div
        className="expand-toggle-one"
        onClick={toggleExpandedOne}
        onKeyDown={toggleExpandedOne}
        role="button"
        tabIndex={0}
      >
        <strong>Private bathroom</strong>
        {' '}
        <FontAwesomeIcon icon={expandedone ? faSquareCaretUp : faSquareCaretDown} />
      </div>

      {expandedone && (
      <div className="private_bathroom">
        <div className="private_bathroom_1">
          {privateBathroomOne.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="private_bathroom_2">
          {privateBathroomTwo.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="private_bathroom_3">
          {privateBathroomThree.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
      </div>
      )}
      <div
        className="expand-toggle-two"
        onClick={toggleExpandedTwo}
        onKeyDown={toggleExpandedTwo}
        role="button"
        tabIndex={0}
      >
        <strong>View</strong>
        {' '}
        <FontAwesomeIcon icon={expandedtwo ? faSquareCaretUp : faSquareCaretDown} />
      </div>

      {expandedtwo && (
      <div className="view">
        <div className="view_1">
          {viewOne.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="view_2">
          {viewTwo.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="view_3">
          {viewThree.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
      </div>
      )}
      <div
        className="expand-toggle-three"
        onClick={toggleExpandedThree}
        onKeyDown={toggleExpandedThree}
        role="button"
        tabIndex={0}
      >
        <strong>Apartment Facilities</strong>
        {' '}
        <FontAwesomeIcon icon={expandedthree ? faSquareCaretUp : faSquareCaretDown} />
      </div>

      {expandedthree && (
      <div className="room_facilities">
        <div className="room_facilities_1">
          {facilitiesOne.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="room_facilities_2">
          {facilitiesTwo.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
        <div className="room_facilities_3">
          {facilitiesThree.map(({ icon, text }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={index}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {' '}
                { text}
              </span>
            </div>
          ))}
        </div>
      </div>
      )}
    </div>

  );
};

RoomAmenities.propTypes = {
  roomName: PropTypes.string.isRequired,
};

export default RoomAmenities;
