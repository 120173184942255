import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './dashboard.css';

function Dashboard() {
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({
    id: '',
    name: '',
    photo: null,
    avatar_url: '',
    email: '',
    phone_number: '',
    country: '',
  });

  const fetchUserData = useCallback(() => {
    const token = sessionStorage.getItem('token');
    axios
      .get(`https://asprovaltam.com/users/${userId}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setUser(response.data);
        sessionStorage.setItem('userName', response.data.name);
        sessionStorage.setItem('userEmail', response.data.email);
        sessionStorage.setItem('userPhoneNumber', response.data.phone_number);
        sessionStorage.setItem('userCountry', response.data.country);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, [userId]);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserId) {
      setUserId(parseInt(storedUserId, 10));
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId, fetchUserData]);

  useEffect(() => {
    const handleUserUpdate = () => {
      fetchUserData();
    };

    window.addEventListener('userUpdated', handleUserUpdate);

    return () => {
      window.removeEventListener('userUpdated', handleUserUpdate);
    };
  }, [fetchUserData]);

  return (
    <div>
      <div className="content--header">
        <h2 className="header--title">Personal info</h2>
      </div>
      <div className="content--info">
        <div className="info-left">
          {user.avatar_url && (
            <img
              alt="Avatar"
              src={`https://asprovaltam.com${user.avatar_url}`}
              className="dashboard-image"
            />
          )}
          <div className="user-info1">
            <div className="info-value1">{user.name}</div>
          </div>
          <div className="left-info-text">
            {user.id === 2 ? 'Partner' : 'Client'}
          </div>
        </div>
        <div className="info-container">
          <div className="info-right">
            <div className="user-info">
              <div className="info-label bigger-label">Name</div>
              <div className="info-value">{user.name}</div>
            </div>
            <div className="user-info">
              <div className="info-label bigger-label">Email</div>
              <div className="info-value">{user.email}</div>
            </div>
            <div className="user-info">
              <div className="info-label bigger-label">Phone</div>
              <div className="info-value">{user.phone_number}</div>
            </div>
            <div className="user-info">
              <div className="info-label bigger-label">Country</div>
              <div className="info-value">{user.country}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
