// SuccessComponent.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/successcomponent.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const SuccessComponent = () => {
  const { reservationId } = useParams();
  const [userName, setUsername] = useState('');
  const [roomName, setRoomName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve values from sessionStorage
    const storedUsername = sessionStorage.getItem('userName');
    const storedRoomName = sessionStorage.getItem('roomName');
    const storedStartDate = sessionStorage.getItem('startDate');
    const storedEndDate = sessionStorage.getItem('endDate');
    const storedTotalPrice = sessionStorage.getItem('totalPrice');
    const storedUserEmail = sessionStorage.getItem('userEmail');

    // Set the state with the retrieved values
    setUsername(storedUsername || 'Guest');
    setRoomName(storedRoomName || 'Unknown Room');
    setStartDate(storedStartDate || 'Start Date');
    setEndDate(storedEndDate || 'End Date');
    setTotalPrice(storedTotalPrice || 'Total Price');
    setUserEmail(storedUserEmail || 'Email');
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const handleClose = () => {
    // You can navigate to a different route or perform any other action
    navigate('/'); // Replace with the desired route
  };

  return (
    <div className="success-wrapper">
      <header className="success-header" />
      <div className="success-container">
        <h1 className="success-title">Payment Successful!</h1>
        <div className="success">
          <i className="fas fa-check-circle check-icon" />
          <p className="success-info">
            Guest:
            {' '}
            <span className="guest-name">{userName}</span>
          </p>
          <p className="success-info">
            Reservation ID:
            {' '}
            <span className="reservation-id">{reservationId}</span>
          </p>
          <p className="success-info">
            Room Name:
            {' '}
            <span className="room-name">{roomName}</span>
          </p>
          <p className="success-info">
            Check in:
            {' '}
            <span className="room-id">{startDate}</span>
          </p>
          <p className="success-info">
            Check out:
            {' '}
            <span className="room-id">{endDate}</span>
          </p>
          <p className="success-info">
            Total Price:
            {' '}
            <span className="room-id">
              {totalPrice}
              {' '}
              €
            </span>
          </p>
          <p className="success-info">
            {' '}
            A copy of this form has been sent to your email at:
            {' '}
            <span className="room-id">
              {userEmail}
            </span>
          </p>
        </div>
        <div className="buttons-print-close">
          {/* Print button */}
          <button className="print-button" type="button" onClick={handlePrint}>
            Print
          </button>

          {/* Close button */}
          <button className="close-button" type="button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessComponent;
