import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { ChakraProvider } from '@chakra-ui/react';
// eslint-disable-next-line import/no-named-as-default-member
import App from './App';
import store from './redux/store/store';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        {/* <ChakraProvider> */}
        <App />
        {/* </ChakraProvider> */}
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
);
