import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import logo3 from '../images/logo 3.png';
import '../styles/min_width_1440.css';
import '../styles/footer.css';

const Footer = () => (
  <div className="footer-content">
    <p>
      <img src={logo3} alt="Logo" className="footer-logo" />
      © 2IDRJA. All rights reserved, 2024

      <a
        href="https://www.facebook.com/asprovaltam/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit our Facebook page"
      >
        <FontAwesomeIcon icon={faFacebook} className="facebook1-icon" />
      </a>
      {/* <a
        href="https://www.instagram.com/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit our Instagram page"
      >
        <FontAwesomeIcon icon={faInstagram} className="instagram-icon" />
      </a> */}
    </p>
  </div>
);

export default Footer;
