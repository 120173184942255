/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaCard from './MediaCard';
import '../styles/min_width_1440.css';

const AvailableRoomsSection = ({ availableRooms }) => {
  const [error, setError] = useState(null);
  const [availabilityChecked, setAvailabilityChecked] = useState(false);
  const [datesValid, setDatesValid] = useState(false);

  useEffect(() => {
    const checkAvailability = async () => {
      const storedStartDate = sessionStorage.getItem('startDate');
      const storedEndDate = sessionStorage.getItem('endDate');

      if (storedStartDate && storedEndDate) {
        try {
          const apiUrl = 'https://asprovaltam.com/rooms/check_availability';
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              start_date: storedStartDate,
              end_date: storedEndDate,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }

          const data = await response.json();
          // Store the fetched data in local storage
          sessionStorage.setItem('availabilityData', JSON.stringify(data));
          setError(null);
          setDatesValid(true);
        } catch (error) {
          console.error('Error checking availability:', error);
          setError('Error checking availability. Please try again.');
          setDatesValid(false);
        } finally {
          setAvailabilityChecked(true);
        }
      }
    };

    checkAvailability();
  }, []);

  if (!availabilityChecked) {
    return null;
  }

  return (
    <div className="room-availability">
      {error && <div className="error-message">{error}</div>}
      {datesValid && availableRooms && (
        <div className="available-rooms-container">
          {Object.entries(availableRooms).map(([roomId, roomData]) => (
            <div key={roomId} className="col-md-6">
              <MediaCard
                className="mediacard"
                roomId={parseInt(roomId, 10)}
                roomName={roomData.name}
                description={`Available dates:\n${roomData.available_dates
                  .map(({ date, price }) => `Date: ${date}, Price: ${price}`)
                  .join('\n')}`}
                imageUrl="https://media.istockphoto.com/id/1398814566/photo/interior-of-small-apartment-living-room-for-home-office.jpg?s=612x612&w=0&k=20&c=8clwg8hTpvoEwL7253aKdYAUuAp1-usFOacNR5qX-Rg="
              />
            </div>
          ))}
        </div>
      )}
      {!datesValid && <div>Invalid Dates</div>}
    </div>
  );
};

AvailableRoomsSection.propTypes = {
  availableRooms: PropTypes.objectOf(
    PropTypes.shape({
      available_dates: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

export default AvailableRoomsSection;
