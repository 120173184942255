import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../../styles/auth.css';
import { toast } from 'react-hot-toast';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { userId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const resetToken = searchParams.get('reset_password_token');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;

    if (
      !uppercaseRegex.test(password)
      || !numberRegex.test(password)
      || password.length < 8
    ) {
      toast.dismiss();
      toast.error(
        'Password must contain at least one uppercase letter, one number, and be at least 8 characters long',
      );
      return;
    }

    if (password !== confirmPassword) {
      toast.dismiss();
      toast.error('Passwords do not match');
      return;
    }

    const response = await fetch(
      `https://asprovaltam.com/users/${userId}/reset_password?reset_password_token=${resetToken}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
          password_confirmation: confirmPassword,
          reset_password_token: resetToken,
        }),
      },
    );

    if (response.ok) {
      toast.success('Password updated successfully');
      navigate('/signin');
    } else {
      const errorMessage = await response.json();
      toast.error(errorMessage.error);
    }
  };

  return (
    <section className="section">
      <div className="form-box-reset">
        <div className="form-value">
          <form onSubmit={handleSubmit}>
            <h2 className="text">Reset Password</h2>
            <div className="inputbox">
              <ion-icon name="lock-open-outline" />
              <input
                type="password"
                className="input"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="password">New Password:</label>
            </div>
            <div className="inputbox">
              <ion-icon name="lock-closed-outline" />
              <input
                type="password"
                className="input"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <label htmlFor="confirmPassword">Confirm Password:</label>
            </div>
            <button className="button" type="submit">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
