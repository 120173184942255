import React from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import Card from '@mui/material/Card';

import 'react-calendar/dist/Calendar.css';
import '../styles/AvailabilityCalendar.css';

const AvailabilityCalendar = ({ roomData }) => {
  const availableDates = roomData?.available_dates.map(({ date }) => new Date(date));

  const calendarStyle = {
    position: 'relative',
    width: 'auto',
    left: '50%',
    bottom: '100%',
    transform: 'translateX(-50%)',
    padding: '16px',
    backgroundColor: '#f7f7f6',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  };

  return (
    <Card style={calendarStyle} className="calendar-popup">
      <Calendar
        className="custom-calendar"
        tileClassName={({ date }) => (
          availableDates.some((availableDate) => availableDate.toDateString() === date.toDateString())
            ? 'available-date'
            : 'unavailable-date'
        )}
      />
    </Card>
  );
};

AvailabilityCalendar.propTypes = {
  roomData: PropTypes.shape({
    available_dates: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default AvailabilityCalendar;
