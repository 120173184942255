import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import '../../styles/auth.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactFlagsSelect from 'react-flags-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GetAppIcon from '@mui/icons-material/GetApp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PolicyAndTerms from './PolicyAndTerms';

const SignupForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('');
  const [country, setCountry] = useState('');
  const [agree, setAgree] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('reloaded')) {
      sessionStorage.clear();
    }
    sessionStorage.removeItem('reloaded');

    const savedName = sessionStorage.getItem('name');
    const savedEmail = sessionStorage.getItem('email');
    const savedPassword = sessionStorage.getItem('password');
    const savedConfirmPassword = sessionStorage.getItem('confirmPassword');
    const savedPhoneNumber = sessionStorage.getItem('phoneNumber');
    const savedPhoneCountry = sessionStorage.getItem('phoneCountry');
    const savedCountry = sessionStorage.getItem('country');
    const savedAgree = sessionStorage.getItem('agree') === 'true';
    const savedPolicy = sessionStorage.getItem('policy') === 'true';

    if (savedName) setName(savedName);
    if (savedEmail) setEmail(savedEmail);
    if (savedPassword) setPassword(savedPassword);
    if (savedConfirmPassword) setConfirmPassword(savedConfirmPassword);
    if (savedPhoneNumber) setPhoneNumber(savedPhoneNumber);
    if (savedPhoneCountry) setPhoneCountry(savedPhoneCountry);
    if (savedCountry) setCountry(savedCountry);
    setAgree(savedAgree);
    setPolicy(savedPolicy);
    setShowAdditionalForm(savedAgree);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('name', name);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('password', password);
    sessionStorage.setItem('confirmPassword', confirmPassword);
    sessionStorage.setItem('phoneNumber', phoneNumber);
    sessionStorage.setItem('phoneCountry', phoneCountry);
    sessionStorage.setItem('country', country);
    sessionStorage.setItem('agree', agree);
    sessionStorage.setItem('policy', policy);
  }, [
    name,
    email,
    password,
    confirmPassword,
    phoneNumber,
    phoneCountry,
    country,
    agree,
    policy,
  ]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('reloaded', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSignUp = async () => {
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;

    if (
      !uppercaseRegex.test(password)
      || !numberRegex.test(password)
      || password.length < 8
    ) {
      toast.dismiss();
      toast.error(
        'Password must contain at least one uppercase letter, one number, and be at least 8 characters long',
      );
      return;
    }

    if (password !== confirmPassword) {
      toast.dismiss();
      toast.error('Passwords do not match');
      return;
    }

    if (!agree) {
      toast.dismiss();
      toast.error(
        'You must agree to the Privacy policy & Terms and Conditions',
      );
      return;
    }

    try {
      const response = await axios.post('https://asprovaltam.com/users', {
        user: {
          name,
          email,
          password,
          password_confirmation: confirmPassword,
          phone_number: phoneNumber,
          country,
          privacy_policy_accepted: true,
        },
      });

      console.log('Sign up response:', response);

      if (response.status === 200 || response.status === 201) {
        toast.success('Sign up successful');
        navigate('/users/notification', { state: { email } });
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error(
        'Sign up error:',
        error.response ? error.response.data : error.message,
      );
      toast.error(
        `Sign up failed: ${error.response?.data?.message || error.message}`,
      );
    }
  };

  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  const handleCheckboxChange = (event) => {
    setAgree(event.target.checked);
    setShowAdditionalForm(event.target.checked);
  };

  const handlePolicyCheckboxChange = (event) => {
    setPolicy(event.target.checked);
  };

  const handleAdditionalFormSubmit = (e) => {
    e.preventDefault();
    if (!policy) {
      toast.dismiss();
      toast.error(
        'You must agree to the Privacy policy & Terms and Conditions to proceed',
      );
      return;
    }
    setShowAdditionalForm(false);
  };

  return (
    <section className="section">
      <div className="form-container">
        {!showAdditionalForm && (
          <div className="form-box-signup">
            <div className="form-value">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignUp();
                }}
              >
                <h2 className="text">Sign Up</h2>
                <div className="inputbox">
                  <ion-icon name="mail-outline" />
                  <input
                    className="input"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label htmlFor="fullname">Full name</label>
                </div>
                <div className="inputbox">
                  <ion-icon name="lock-closed-outline" />
                  <input
                    className="input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="inputbox">
                  <ion-icon className="icon" name="lock-closed-outline" />
                  <div className="password-wrapper">
                    <input
                      className="input"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <label htmlFor="password">Password</label>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      className="password-toggle-icon"
                    >
                      {showPassword ? (
                        <Visibility style={{ color: 'white' }} />
                      ) : (
                        <VisibilityOff style={{ color: 'white' }} />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="inputbox">
                  <ion-icon name="lock-closed-outline" />
                  <div className="password-wrapper">
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="input"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                      className="password-toggle-icon"
                    >
                      {showConfirmPassword ? (
                        <Visibility style={{ color: 'white' }} />
                      ) : (
                        <VisibilityOff style={{ color: 'white' }} />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    country={phoneCountry}
                    onCountryChange={setPhoneCountry}
                    required
                  />
                </div>
                <div>
                  <ReactFlagsSelect
                    style={{
                      color: 'white',
                    }}
                    placeholder="Select a country"
                    selected={country}
                    onSelect={handleCountrySelect}
                    searchable
                    searchPlaceholder="Search for a country"
                    required
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 30 },
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'gray',
                          },
                        }}
                        checked={agree}
                        onChange={handleCheckboxChange}
                      />
                    )}
                    label={(
                      <>
                        <h5 style={{ color: 'white' }}>
                          Privacy policy & Terms and Conditions
                        </h5>
                      </>
                    )}
                  />
                </div>
                <button className="signup-button" type="submit">
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        )}
        {showAdditionalForm && (
          <div className="form-box-additional">
            <div className="form-value">
              <form onSubmit={handleAdditionalFormSubmit}>
                <h2 className="text-policy">Privacy policy &</h2>
                <h2 className="text-policy">Terms and Conditions</h2>
                <div className="scroll-box">
                  <PolicyAndTerms />
                </div>
                <div className="download-icon">
                  <Link to="/path/to/your/file.pdf" download>
                    <GetAppIcon
                      sx={{ fontSize: 30, color: 'white', marginLeft: '310px' }}
                    />
                  </Link>
                </div>
                <div>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 30 },
                          color: 'white',
                          '&.Mui-checked': { color: 'gray' },
                        }}
                        checked={policy}
                        onChange={handlePolicyCheckboxChange}
                      />
                    )}
                    label={<h5 style={{ color: 'white' }}>I agree</h5>}
                  />
                </div>
                <button className="signup-button" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SignupForm;
