import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import AvailabilityCalendar from './AvailabilityCalendar';

const ResponsiveAvailabilityCalendar = ({ roomData, handleCalendarButtonClick }) => {
  const isSmallScreen = useMediaQuery('(max-width:375px)');

  return (
    <div className="calendar-content" style={{ position: 'relative' }}>
      <AvailabilityCalendar roomData={roomData} />
      {isSmallScreen && (
        <Button
          sx={{ position: 'absolute', top: '30px', left: '10px' }}
          onClick={handleCalendarButtonClick}
        >
          <FontAwesomeIcon icon={faCalendar} style={{ width: '25px', height: '25px', color: '#5da0ff' }} />
        </Button>
      )}
    </div>
  );
};

ResponsiveAvailabilityCalendar.propTypes = {
  roomData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    available_dates: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  handleCalendarButtonClick: PropTypes.func.isRequired,
};

export default ResponsiveAvailabilityCalendar;
