import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FaBars, FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  faPhone,
  faEnvelope,
  faUserCircle,
  faUser,
  faTasks,
  faGear,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import logo from '../../images/logo.png';
import locationImg from '../../images/locationImg.png';
import '../../styles/navbar.css';
import Avatar from './Avatar';

function Navbar() {
  const navRef = useRef();
  const [isResponsive, setIsResponsive] = useState(false);
  const [isLargeDevice, setIsLargeDevice] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const [isUserSignedIn, setIsUserSignedIn] = useState(true);
  const [userIdAvatar, setUserIdAvatar] = useState(null);
  const [user, setUser] = useState({
    id: '',
    name: '',
    photo: null,
    avatar_url: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserId) {
      setUserIdAvatar(parseInt(storedUserId, 10));
    }
  }, []);

  useEffect(() => {
    if (userIdAvatar) {
      const token = sessionStorage.getItem('token');
      axios
        .get(`https://asprovaltam.com/users/${userIdAvatar}`, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [userIdAvatar]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    setIsUserSignedIn(!!token);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 1023);
      setIsLargeDevice(window.innerWidth >= 1024);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    handleResize();
    handleScroll();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !isMenuOpen
        && navRef.current
        && !navRef.current.contains(event.target)
      ) {
        setIsMenuOpen(!isMenuOpen);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleNavbar = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSignOut = () => {
    sessionStorage.clear();
    setIsUserSignedIn(false);
    navigate('/');
  };

  const userId = sessionStorage.getItem('userId');

  const handleMyReservationsClick = () => {
    if (!userId) {
      toast.error('Please log in or register');
    }
  };

  const handleEditClick = () => {
    if (!userId) {
      toast.error('Please log in or register');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleMyReservationsClick();
    }
  };

  return (
    <header className={`nav-header ${isScrolled ? 'scrolled' : ''}`}>
      <img className="logo" src={logo} alt="logo.png" />
      <nav
        className={`navbar-nav ${isMenuOpen ? 'responsive_nav' : ''}`}
        ref={navRef}
      >
        {isResponsive && (
          <div className="hamburger-links">
            <div className="avatar">
              {user.avatar_url ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="220px"
                  height="220px"
                >
                  <img
                    alt=""
                    width="108px"
                    height="108px"
                    className="avatar-image-navbar"
                    src={`https://asprovaltam.com${user.avatar_url}`}
                    onError={(e) => console.error('Error loading image:', e, e.target.src)}
                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                  />
                </Box>
              ) : (
                <div
                  style={{
                    marginBottom: '50px',
                    marginLeft: '40px',
                    color: 'gray',
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} size="8x" />
                </div>
              )}
            </div>
            {isUserSignedIn ? (
              <div className="navigation-buttons-nav">
                <div>
                  <Link to="profile/dashboard" className="button-nav">
                    <FontAwesomeIcon icon={faUser} />
                    {' '}
                    Profile
                  </Link>
                </div>
                <div>
                  <Link
                    to={`profile/users/${userId}/reservations`}
                    onClick={handleMyReservationsClick}
                    onKeyDown={handleKeyPress}
                    className="button-nav"
                  >
                    <FontAwesomeIcon icon={faTasks} />
                    {' '}
                    <Outlet />
                    My reservations
                  </Link>
                </div>
                <div>
                  <Link
                    to={`profile/users/${userId}/settings`}
                    onClick={handleEditClick}
                    onKeyDown={handleKeyPress}
                    className="button-nav"
                  >
                    <FontAwesomeIcon icon={faGear} />
                    {' '}
                    Settings
                  </Link>
                </div>
                <div>
                  <Link
                    to="/"
                    onClick={handleSignOut}
                    className="button-logout-nav"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    {' '}
                    Logout
                  </Link>
                </div>
              </div>
            ) : (
              <div className="navbar-buttons">
                <div>
                  <Link to="/signin" className="button-nav">
                    Sign In
                  </Link>
                </div>
                <div>
                  <Link to="/signup" className="button-nav">
                    Sign Up
                  </Link>
                </div>
              </div>
            )}
            <div className="phone-hamburger-nav">
              <span className="phone-hamburger-label-nav" aria-hidden="true">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="phone-hamburger-icon-nav"
                />
              </span>
              <a className="phone-link-nav" href="tel:+38971292496">
                +389 71 292 496
              </a>
            </div>
            <div className="email-hamburger-nav">
              <span className="email-icon-nav" aria-label="Email">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="envelope-hamburger-icon-nav"
                />
              </span>
              <a className="email-link-nav" href="mailto:asprovaltam@yahoo.com">
                asprovaltam@yahoo.com
              </a>
            </div>
            <div>
              <div className="icons-hmaburger-nav">
                <a
                  href="https://www.google.com/maps/place/Asprovalta+M+Apartments/@40.7240395,23.7063886,17z/data=!3m1!4b1!4m16!1m9!4m8!1m0!1m6!1m2!1s0x14a91f22438b3a3d:0xcee56071c2b751c8!2sAsprovalta+M+Apartments,+Leof.+Egnatias,+Asprovalta+570+21,+Greece!2m2!1d23.7089635!2d40.7240355!3m5!1s0x14a91f22438b3a3d:0xcee56071c2b751c8!8m2!3d40.7240355!4d23.7089635!16s%2Fg%2F11h4ql_hpc?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="location-hamburger-icon-nav"
                    src={locationImg}
                    alt="locationImg.png"
                  />
                </a>
                <a
                  href="https://www.facebook.com/asprovaltam/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fb-label" aria-label="Facebook">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="facebook-hamburger-icon-nav"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        )}
        {isLargeDevice && !isResponsive && (
          <>
            <div className="facebook-nav">
              <a
                href="https://www.facebook.com/asprovaltam/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="fb-label" aria-label="Facebook">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="facebook-icon-nav"
                  />
                </span>
              </a>
            </div>
            <div className="phone-nav">
              <span className="phone-label-nav" aria-hidden="true">
                <FontAwesomeIcon icon={faPhone} className="phone-icon-nav" />
              </span>
              <a className="phone-link-nav" href="tel:+38971292496">
                +389 71 292 496
              </a>
            </div>
            <div className="email-nav">
              <span className="email-icon-nav" aria-label="Email">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="envelope-icon-nav"
                />
              </span>
              <a className="email-link-nav" href="mailto:asprovaltam@yahoo.com">
                asprovaltam@yahoo.com
              </a>
            </div>
            <div>
              <a
                href="https://www.google.com/maps/place/Asprovalta+M+Apartments/@40.7240395,23.7063886,17z/data=!3m1!4b1!4m16!1m9!4m8!1m0!1m6!1m2!1s0x14a91f22438b3a3d:0xcee56071c2b751c8!2sAsprovalta+M+Apartments,+Leof.+Egnatias,+Asprovalta+570+21,+Greece!2m2!1d23.7089635!2d40.7240355!3m5!1s0x14a91f22438b3a3d:0xcee56071c2b751c8!8m2!3d40.7240355!4d23.7089635!16s%2Fg%2F11h4ql_hpc?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="location-icon-nav"
                  src={locationImg}
                  alt="locationImg.png"
                />
              </a>
            </div>
          </>
        )}
        <button
          className="nav-btn nav-close-btn"
          onClick={toggleNavbar}
          aria-label="Close Navigation"
          type="button"
        >
          <FaTimes aria-hidden="true" />
        </button>
      </nav>
      {isLargeDevice && !isResponsive && <Avatar />}

      <button
        className="nav-btn"
        onClick={toggleNavbar}
        aria-label="Open Navigation"
        type="button"
      >
        <FaBars aria-hidden="true" />
      </button>
    </header>
  );
}

export default Navbar;
