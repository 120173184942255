import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import NotesIcon from '@mui/icons-material/Notes';
import BookIcon from '@mui/icons-material/Book';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { motion } from 'framer-motion';
import './sidebar.css';

export const fetchAndUpdateUser = (setUser) => {
  const storedUserId = sessionStorage.getItem('userId');
  if (storedUserId) {
    const id = parseInt(storedUserId, 10);
    const token = sessionStorage.getItem('token');
    return axios
      .get(`https://asprovaltam.com/users/${id}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const {
          id, name, photo, avatar_url: avatarUrl,
        } = response.data;

        console.log('Fetched user data:', {
          id,
          name,
          photo,
          avatarUrl,
        });
        setUser((prevUser) => ({
          ...prevUser,
          id,
          name,
          photo,
          avatarUrl,
        }));
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error('User not found');
        } else {
          console.error('Error fetching user data:', error);
        }
      });
  }
  return Promise.resolve(null);
};

const Sidebar = () => {
  const [selected, setSelected] = useState('');
  const [userId, setUserId] = useState(null);
  const [isUserSignedIn, setIsUserSignedIn] = useState(true);
  const [user, setUser] = useState({
    id: '',
    name: '',
    photo: null,
    avatarUrl: '',
  });
  const [expanded, setExpanded] = useState(false);
  const sidebarRef = useRef(null);
  const barsRef = useRef(null);

  const [userName, setUserName] = useState(sessionStorage.getItem('userName'));
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarVariants = {
    true: {
      left: '0',
    },
    false: {
      left: '-60%',
    },
  };

  useEffect(() => {
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserId) {
      const id = parseInt(storedUserId, 10);
      setUserId(id);
      const token = sessionStorage.getItem('token');
      axios
        .get(`https://asprovaltam.com/users/${id}`, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          const userData = response.data;
          userData.avatarUrl = userData.avatar_url;
          delete userData.avatar_url;
          setUser(userData);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error('User not found');
          } else {
            console.error('Error fetching user data:', error);
          }
        });
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('dashboard')) {
      setSelected('dashboard');
    } else if (path.includes('settings')) {
      setSelected('settings');
    } else if (path.includes('reservations')) {
      setSelected('reservations');
    } else {
      setSelected('');
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleUserUpdate = () => {
      fetchAndUpdateUser(setUser);
    };

    window.addEventListener('userUpdated', handleUserUpdate);

    return () => {
      window.removeEventListener('userUpdated', handleUserUpdate);
    };
  }, []);

  useEffect(() => {
    setUserName(user.name);
    sessionStorage.setItem('userName', user.name);
  }, [user]);

  const handleItemClick = (itemName) => {
    if (!userId) {
      toast.error('Please log in or register');
    } else {
      setSelected(itemName);
    }
  };

  const handleGoToHomePage = () => {
    navigate('/');
  };

  const handleSignOut = () => {
    sessionStorage.clear();
    setIsUserSignedIn(false);
    navigate('/');
  };

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current
      && !sidebarRef.current.contains(event.target)
      && !barsRef.current.contains(event.target)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [expanded]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setExpanded(!expanded);
    }
  };

  return (
    <>
      <div
        className="bars"
        style={expanded ? { left: '40%' } : { left: '10%' }}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={handleKeyDown}
        aria-label={expanded ? 'Collapse menu' : 'Expand menu'}
        role="button"
        tabIndex={0}
        ref={barsRef}
      >
        <MenuIcon />
      </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        initial={false}
        animate={expanded ? 'true' : 'false'}
        ref={sidebarRef}
      >
        <div className="menu">
          <Box className="avatar-image-box" mb="40px">
            <Box display="flex" justifyContent="center" alignItems="center">
              {user.avatarUrl && (
                <img
                  alt="Avatar"
                  width="150px"
                  height="150px"
                  src={`https://asprovaltam.com${user.avatarUrl}`}
                  className="avatar-image"
                />
              )}
            </Box>
          </Box>
          <div className="logo-sidebar">
            <BookIcon className="logo-icon" />
            <span className="username">{userName}</span>
          </div>
          <Link
            to="dashboard"
            className={`menuItem ${selected === 'dashboard' ? 'active' : ''}`}
            onClick={() => handleItemClick('dashboard')}
          >
            <HomeIcon className="menu-icon" />
            Dashboard
          </Link>
          <Link
            to={`users/${userId}/settings`}
            className={`menuItem ${selected === 'settings' ? 'active' : ''}`}
            onClick={() => handleItemClick('settings')}
          >
            <SettingsIcon className="menu-icon" />
            Settings
          </Link>
          <Link
            to={`users/${userId}/reservations`}
            className={`menuItem ${
              selected === 'reservations' ? 'active' : ''
            }`}
            onClick={() => handleItemClick('reservations')}
          >
            <NotesIcon className="menu-icon" />
            My Reservations
          </Link>
          <Link to="/" className="menuItem" onClick={handleGoToHomePage}>
            <LoginIcon />
            Home
          </Link>
          {isUserSignedIn && (
            <Link to="/" className="menuItem" onClick={handleSignOut}>
              <LogoutIcon />
              Logout
            </Link>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
