/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/weather.css';
import '../styles/min_width_1440.css';
import { FaThermometerHalf } from 'react-icons/fa'; // Import icons from react-icons library

const Weather = () => {
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecastData, setForecastData] = useState(null);

  const apiKey = process.env.REACT_APP_WEATHER_API_KEY;

  const city = 'Asprovalta';
  const currentWeatherApiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}`;
  const forecastApiUrl = `https://api.openweathermap.org/data/2.5/forecast?q=${city}&appid=${apiKey}`;

  useEffect(() => {
    const fetchCurrentWeather = async () => {
      try {
        const response = await axios.get(currentWeatherApiUrl);
        setCurrentWeather(response.data);
      } catch (error) {
        console.error('Error fetching current weather data:', error);
      }
    };

    const fetchForecastData = async () => {
      try {
        const response = await axios.get(forecastApiUrl);
        setForecastData(response.data);
      } catch (error) {
        console.error('Error fetching forecast data:', error);
      }
    };

    fetchCurrentWeather();
    fetchForecastData();
  }, [currentWeatherApiUrl, forecastApiUrl]);

  const getAbbreviatedDay = (index) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date().getDay();
    const nextDay = (today + index) % 7; // Ensure it doesn't go beyond Saturday
    return daysOfWeek[nextDay];
  };

  return (
    <div className="weather_container">
      {currentWeather && (
      <div className="current_weather">
        <h2 className="current_temp">
          <FaThermometerHalf className="thermo" />
          {' '}
          {(currentWeather.main.temp - 273.15).toFixed(0)}
          {' '}
          °C
          <div className="blinking-arrow">
            <span />
            <span />
            <span />
          </div>
          {' '}
        </h2>
        <div className="current_temp_hover">
          {forecastData && forecastData.list && (
          <div className="forecast_weather">
            <div className="forecast_days">
              {forecastData.list.slice(1, 6).map((forecast, index) => (
                <div key={forecast.dt} className="daily-forecast">
                  <p>{getAbbreviatedDay(index + 1)}</p>
                  <p>
                    {(forecast.main.temp - 273.15).toFixed(0)}
                    {' '}
                    °C
                  </p>
                  {forecast.weather.length > 0 && (
                  <img
                    src={`http://openweathermap.org/img/w/${forecast.weather[0].icon}.png`}
                    alt={forecast.weather[0].description}
                  />
                  )}
                </div>
              ))}
            </div>
          </div>
          )}
        </div>
      </div>
      )}
    </div>
  );
};

export default Weather;
