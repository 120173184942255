import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/checkavailiability.css';
import '../styles/min_width_1440.css';
import toast from 'react-hot-toast';

const RoomAvailabilityCheck = ({
  renderAvailability = false,
  renderInputs = false,
  onAvailabilityChange = null,
}) => {
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [capacity, setCapacity] = useState(1);
  const [sameDateErrorShown, setSameDateErrorShown] = useState(false);

  const handleStartDateChange = (date) => {
    if (endDate && date === endDate) {
      if (!sameDateErrorShown) {
        toast.dismiss();
        toast.error('Start date and end date cannot be the same');
        setSameDateErrorShown(true);
      }
      return;
    }
    setStartDate(date);
    if (date > formattedToday) {
      setEndDate('');
    }
    setSameDateErrorShown(false);
  };

  const handleEndDateChange = (date) => {
    if (startDate && date === startDate) {
      if (!sameDateErrorShown) {
        toast.dismiss();
        toast.error('Start date and end date cannot be the same');
        setSameDateErrorShown(true);
      }
      return;
    }
    setEndDate(date);
    setSameDateErrorShown(false);
  };

  const checkAvailability = async () => {
    if (startDate === '' || endDate === '') {
      toast.dismiss();
      toast.error('Please select both start and end dates');
      return;
    }

    if (startDate > endDate) {
      toast.dismiss();
      toast.error('End date must be after start date');
      return;
    }

    const apiUrl = 'https://asprovaltam.com/rooms/check_availability';
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_date: startDate,
          end_date: endDate,
          capacity,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      console.log('Data fetched successfully:', data);

      if (Object.keys(data.available_dates_per_room).length === 0) {
        toast.dismiss();
        toast.error('No rooms available in the desired period');
        return;
      }

      sessionStorage.setItem('startDate', startDate);
      sessionStorage.setItem('endDate', endDate);

      if (onAvailabilityChange) {
        onAvailabilityChange(data.available_dates_per_room);
      }

      const contentSection = document.getElementById('content-section');
      if (contentSection) {
        setTimeout(() => {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    } catch (error) {
      toast.dismiss();
      toast.error('Error checking availability. Please try again.');
    }
  };

  return (
    <div className="container">
      {renderInputs && (
        <div className="dates">
          <label className="startDate" htmlFor="startDate">
            Start Date
            <input
              id="startDate"
              type="date"
              value={startDate}
              min={formattedToday}
              onChange={(e) => handleStartDateChange(e.target.value)}
            />
          </label>
          <label className="endDate" htmlFor="endDate">
            End Date
            <input
              id="endDate"
              type="date"
              value={endDate}
              min={startDate || formattedToday}
              onChange={(e) => handleEndDateChange(e.target.value)}
            />
            {startDate && endDate && startDate >= endDate && (
              <p className="error-message">
                End date must be after the start date
              </p>
            )}
          </label>
          <label className="capacity" htmlFor="capacity">
            Capacity
            <input
              id="capacity"
              type="number"
              value={capacity}
              onChange={(e) => setCapacity(parseInt(e.target.value, 10))}
            />
          </label>
          <button
            type="button"
            className="availability-button"
            onClick={checkAvailability}
          >
            Check Availability
          </button>
        </div>
      )}
      {renderAvailability && (
        <div className="row">{/* Render availability data here */}</div>
      )}
    </div>
  );
};

RoomAvailabilityCheck.propTypes = {
  renderAvailability: PropTypes.bool,
  renderInputs: PropTypes.bool,
  onAvailabilityChange: PropTypes.func,
};

export default RoomAvailabilityCheck;
