import React, { useState } from 'react';
import '../../styles/auth.css';
import toast from 'react-hot-toast';

function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        'https://asprovaltam.com/users/forgot_password',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        },
      );
      if (response.ok) {
        toast.success('Reset password email sent successfully');
      } else {
        toast.dismiss();
        toast.error('Failed to send reset password email');
      }
    } catch (error) {
      toast.dismiss();
      toast.error('An error occurred: Failed to load resource', error);
    }
  };

  return (
    <>
      <section className="section">
        <div className="form-box-forgot">
          <div className="form-value">
            <form onSubmit={handleSubmit}>
              <h2 className="text">Forgot Password</h2>
              <div className="inputbox">
                <ion-icon className="icon" name="mail-outline" />
                <input
                  id="email"
                  className="input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              <button className="signup-button" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
