import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import {
  Routes, Route, useLocation, useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import Body from './components/Body';
import SignIn from './components/Auth/SigIn';
import SignUp from './components/Auth/SignUp';
import ReservationForm from './components/ReservationForm';
import SuccessComponent from './components/SuccessComponent';
import MyReservations from './components/Dashboard/MyReservations/MyReservations';
import PaymentSuccess from './components/PaymentSuccess';
import Profile from './components/Profile/Profile';
import Settings from './components/Dashboard/Settings/Settings';
import Dashboard from './components/Dashboard/MainDash/Dashboard';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import ConfirmEmail from './components/Auth/ConfirmEmail';
import NotificationAccConf from './components/Auth/NotificationAccConf';

// Initialize Google Analytics
ReactGA.initialize('G-8VKK5WZN1L'); // Replace with your tracking ID

const useGAEffect = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('userId');
    setIsAuthenticated(!!token);
    if (!token) {
      navigate('/signin', { state: { from: location }, replace: true });
    }
  }, [location, navigate]);

  if (!isAuthenticated) return null;

  return <>{children}</>;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => {
  useGAEffect();

  return (
    <>
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route
          path="/users/:userId/reset_password"
          element={<ResetPassword />}
        />
        <Route path="/users/confirmation" element={<ConfirmEmail />} />
        <Route path="/users/notification" element={<NotificationAccConf />} />
        <Route
          path="/profile"
          element={(
            <RequireAuth>
              <Profile />
            </RequireAuth>
          )}
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users/:userId/settings" element={<Settings />} />
          <Route
            path="users/:userId/reservations"
            element={<MyReservations />}
          />
        </Route>
        <Route
          path="/users/:userId/rooms/:roomId/reservations/:reservationId/success"
          element={<SuccessComponent />}
        />
        <Route
          path="/users/:userId/rooms/:roomId/reservations/:reservationId/payment"
          element={<PaymentSuccess />}
        />
        <Route
          path="/users/:userId/rooms/:roomId/reservations"
          element={<ReservationForm />}
        />
        <Route
          path="/protected"
          element={(
            <RequireAuth>
              <Body />
            </RequireAuth>
          )}
        />
      </Routes>
      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: '8px' }}
        toastOptions={{
          success: {
            duration: 3000,
            style: {
              color: 'green', // Change color to green or any other color you prefer
            },
          },
          error: {
            duration: 3000,
          },
          style: {
            fontSize: '16px',
            maxWidth: '500px',
            padding: '16px 24px',
            backgroundColor: 'white',
            color: 'red',
          },
        }}
      />
    </>
  );
};

export default App;
