import React, { useEffect, useState } from 'react';
import getGoogleReviews from './GoogleReviews';
import '../styles/min_width_1440.css';
import '../styles/review.css';

const Review = () => {
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      const placeId = 'ChIJPTqLQyIfqRQRyFG3wnFg5c4';
      const result = await getGoogleReviews(placeId);
      setReviews(result);
    };

    fetchReviews();

    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 12000);

    return () => clearInterval(intervalId);
  }, [reviews.length]);

  const truncateText = (text) => {
    if (text.length > 100) {
      return `${text.slice(0, 100)}...`;
    }
    return text;
  };

  return (
    <div className="review-box">
      <div className="review-container">
        <div className="review-wrapper">
          <div className="review-card">
            {reviews.length > 0 && (
              <div className="text-container">
                <div className="img-name">
                  <img
                    className="img"
                    src={reviews[(currentIndex - 1 + reviews.length) % reviews.length]?.reviewerImage || ''}
                    alt="Reviewer"
                  />
                  <div className="name-time">
                    <p>{reviews[(currentIndex - 1 + reviews.length) % reviews.length]?.reviewerName || ''}</p>
                    <p className="date-month">
                      {reviews[(currentIndex - 1 + reviews.length) % reviews.length]
                        ? new Date(
                          reviews[(currentIndex - 1 + reviews.length) % reviews.length].time * 1000,
                        ).toLocaleDateString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })
                        : ''}
                    </p>
                  </div>
                </div>
                <p className="heart-container">
                  {Array.from(
                    { length: reviews[(currentIndex - 1 + reviews.length) % reviews.length]?.rating || 0 },
                    (_, index) => (
                      <span key={index} className="heart-filled">
                        ❤
                      </span>
                    ),
                  )}
                  {Array.from(
                    { length: 5 - (reviews[(currentIndex - 1 + reviews.length) % reviews.length]?.rating || 0) },
                    (_, index) => (
                      <span key={index} className="heart-empty">
                        ❤
                      </span>
                    ),
                  )}
                </p>
                <p>{truncateText(reviews[(currentIndex - 1 + reviews.length) % reviews.length]?.text || '')}</p>
              </div>
            )}
          </div>
          <div className="review-card">
            {reviews.length > 0 && (
              <div className="text-container">
                <div className="img-name">
                  <img
                    className="img"
                    src={reviews[currentIndex]?.reviewerImage || ''}
                    alt="Reviewer"
                  />
                  <div className="name-time">
                    <p>{reviews[currentIndex]?.reviewerName || ''}</p>
                    <p>
                      {reviews[currentIndex]
                        ? new Date(reviews[currentIndex].time * 1000).toLocaleDateString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })
                        : ''}
                    </p>
                  </div>
                </div>
                <p className="heart-container">
                  {Array.from(
                    { length: reviews[currentIndex]?.rating || 0 },
                    (_, index) => (
                      <span key={index} className="heart-filled">
                        ❤
                      </span>
                    ),
                  )}
                  {Array.from(
                    { length: 5 - (reviews[currentIndex]?.rating || 0) },
                    (_, index) => (
                      <span key={index} className="heart-empty">
                        ❤
                      </span>
                    ),
                  )}
                </p>
                <p>{truncateText(reviews[currentIndex]?.text || '')}</p>
              </div>
            )}
          </div>
          <div className="review-card">
            {reviews.length > 0 && (
              <div className="text-container">
                <div className="img-name">
                  <img
                    className="img"
                    src={reviews[(currentIndex + 1) % reviews.length]?.reviewerImage || ''}
                    alt="Reviewer"
                  />
                  <div className="name-time">
                    <p>{reviews[(currentIndex + 1) % reviews.length]?.reviewerName || ''}</p>
                    <p className="date-month">
                      {reviews[(currentIndex + 1) % reviews.length]
                        ? new Date(reviews[(currentIndex + 1) % reviews.length].time * 1000).toLocaleDateString(
                          'en-US',
                          {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          },
                        )
                        : ''}
                    </p>
                  </div>
                </div>
                <p className="heart-container">
                  {Array.from(
                    { length: reviews[(currentIndex + 1) % reviews.length]?.rating || 0 },
                    (_, index) => (
                      <span key={index} className="heart-filled">
                        ❤
                      </span>
                    ),
                  )}
                  {Array.from(
                    { length: 5 - (reviews[(currentIndex + 1) % reviews.length]?.rating || 0) },
                    (_, index) => (
                      <span key={index} className="heart-empty">
                        ❤
                      </span>
                    ),
                  )}
                </p>
                <p>{truncateText(reviews[(currentIndex + 1) % reviews.length]?.text || '')}</p>
              </div>
            )}
          </div>
        </div>
        <div className="rev-but">
          <a
            href="https://search.google.com/local/writereview?placeid=ChIJPTqLQyIfqRQRyFG3wnFg5c4"
            target="_blank"
            rel="noopener noreferrer"
            className="write-review-btn"
          >
            Leave a Review
          </a>
        </div>
      </div>
    </div>
  );
};

export default Review;
