import image1 from '../images/1.jpg';
import image2 from '../images/2.jpg';
import image3 from '../images/3.jpg';
import image4 from '../images/4.jpg';
import image5 from '../images/5.jpg';
import image6 from '../images/6.jpg';
import image7 from '../images/7.jpg';
import image8 from '../images/8.jpg';
import image9 from '../images/9.jpg';
import image10 from '../images/10.jpg';
import image11 from '../images/11.jpg';
import image12 from '../images/12.jpg';
import image13 from '../images/13.jpg';
import image14 from '../images/14.jpg';
import image15 from '../images/15.jpg';
import image16 from '../images/16.jpg';
import image17 from '../images/17.jpg';
import image18 from '../images/18.jpg';
import image19 from '../images/19.jpg';
import image20 from '../images/20.jpg';
import image21 from '../images/21.jpg';
import image22 from '../images/22.jpg';
import image23 from '../images/23.jpg';
import image24 from '../images/24.jpg';
import image25 from '../images/25.jpg';

import image26 from '../images/26.jpg';
import image27 from '../images/27.jpg';
import image28 from '../images/28.jpg';
import image29 from '../images/29.jpg';
import image30 from '../images/30.jpg';
import image31 from '../images/31.jpg';
import image32 from '../images/32.jpg';
import image33 from '../images/33.jpg';
import image34 from '../images/34.jpg';
import image35 from '../images/35.jpg';
import image36 from '../images/36.jpg';
import image37 from '../images/37.jpg';
import image38 from '../images/38.jpg';
import image39 from '../images/39.jpg';
import image40 from '../images/40.jpg';
import image41 from '../images/41.jpg';
import image42 from '../images/42.jpg';
import image43 from '../images/43.jpg';
import image44 from '../images/44.jpg';
import image45 from '../images/45.jpg';
import image46 from '../images/46.jpg';
import image47 from '../images/47.jpg';
import image48 from '../images/48.jpg';
import image49 from '../images/49.jpg';
import image50 from '../images/50.jpg';
import image51 from '../images/51.jpg';

import image52 from '../images/52.png';
import image53 from '../images/53.png';

const RoomImages = {
  'Studio Jana': {
    images: [
      { src: image1 },
      { src: image2 },
      { src: image3 },
      { src: image4 },
      { src: image5 },
      { src: image6 },
      { src: image7 },
      { src: image8 },
      { src: image9 },
      { src: image10 },
      { src: image11 },
      { src: image12 },
      { src: image13 },
      { src: image14 },
      { src: image15 },
      { src: image16 },
      { src: image17 },
      { src: image18 },
      { src: image19 },
      { src: image20 },
      { src: image21 },
      { src: image22 },
      { src: image23 },
      { src: image24 },
      { src: image25 },
    ],
  },
  'Apartment Damjan': {
    images: [
      { src: image26 },
      { src: image27 },
      { src: image28 },
      { src: image29 },
      { src: image30 },
      { src: image31 },
      { src: image32 },
      { src: image33 },
      { src: image34 },
      { src: image35 },
      { src: image36 },
      { src: image37 },
      { src: image38 },
      { src: image39 },
      { src: image40 },
      { src: image41 },
      { src: image42 },
      { src: image43 },
      { src: image44 },
      { src: image45 },
      { src: image46 },
      { src: image47 },
      { src: image48 },
      { src: image49 },
      { src: image50 },
      { src: image51 },
    ],
  },
  Other: {
    images: [
      { src: image52 },
      { src: image53 },
    ],
  },

};
export default RoomImages;
